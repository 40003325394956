import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import RequestDemoForm from "../../components/individualProductPage/RequestDemoForm"
import StealBackground from "../../components/individualProductPage/images/steal-background.jpg"
import SpecBarMachineImage from "../../components/individualProductPage/images/micro-hd-25c-profile.png"
import UsaBackground from "../../components/individualProductPage/images/handmade-in-usa-banner-micro-hd.jpg"
import MachineOverviewImage from "../../components/individualProductPage/images/micro-hd-machine-overview.jpg"
import HeavyDutyBackground from "../../components/individualProductPage/images/heavy-duty-banner-micro-hd.jpg"
import MachineDimensionImage from "../../components/individualProductPage/images/mini-hd-machine-cad-drawings-size-top.jpg"
import YBTJMicroHD from "../../../static/FC-MICRO-HD-YBTJ.pdf"

import ControllerImage from "../../images/choose-your-controller-v2.jpg"

//Thumbnail Images
import CylindricalThumb1Image from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-1.jpg"
import CylindricalThumb2Image from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-4.jpg"
import CylindricalThumb3Image from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-3.jpg"
import CylindricalThumb4Image from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-2.jpg"
import CylindricalThumb5Image from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-5.jpg"
import DiskThumb1 from "../../components/individualProductPage/images/micro-hd-website-crops_0007_26d-thumb-view-1.jpg"
import DiskThumb2 from "../../components/individualProductPage/images/micro-hd-website-crops_0004_26d-thumb-view-4.jpg"
import DiskThumb3 from "../../components/individualProductPage/images/micro-hd-website-crops_0005_26d-thumb-view-3.jpg"
import DiskThumb4 from "../../components/individualProductPage/images/micro-hd-website-crops_0006_26d-thumb-view-2.jpg"
import DiskThumb5 from "../../components/individualProductPage/images/micro-hd-disk-deck-view-brush-view-thumb-5.jpg"
import OrbitalThumb1 from "../../components/individualProductPage/images/micro-hdv2.0.jpg"
import OrbitalThumb2 from "../../components/individualProductPage/images/microhd-v2.0-2.jpg"
import OrbitalThumb3 from "../../components/individualProductPage/images/microhd-v2.0-3.jpg"
import OrbitalThumb4 from "../../components/individualProductPage/images/microhd-v2.0-4.jpg"
import OrbitalThumb5 from "../../components/individualProductPage/images/microhd-v2.0-5.jpg"
//End Thumbnail Images
import '../product-page-styles.scss';

//Main Images
import CylindricalMain1Image from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-1.jpg"
import CylindricalMain2Image from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-4.jpg"
import CylindricalMain3Image from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-3.jpg"
import CylindricalMain4Image from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-2.jpg"
import CylindricalMain5Image from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-5.jpg"
import DiskMain1Image from "../../components/individualProductPage/images/micro-hd-website-crops_0007_26d-view-1.png"
import DiskMain2Image from "../../components/individualProductPage/images/micro-hd-website-crops_0004_26d-view-4.png"
import DiskMain3Image from "../../components/individualProductPage/images/micro-hd-website-crops_0005_26d-view-3.png"
import DiskMain4Image from "../../components/individualProductPage/images/micro-hd-website-crops_0006_26d-view-2.png"
import DiskMain5Image from "../../components/individualProductPage/images/micro-hd-disk-deck-view-brush-view-5.png"

import MediaTile from "../../components/individualProductPage/MediaTile"
import MainSpecBar from "../../components/individualProductPage/FeaturedProduct/MainSpecBar"
import MadeInUSA from "../../components/individualProductPage/MadeInUSA"
import SectionTitle from "../../components/common/SectionTitle"
import MachineOverview from "../../components/individualProductPage/MachineOverview"
import HeavyDuty from "../../components/individualProductPage/HeavyDuty"
import MachineDimensions from "../../components/individualProductPage/MachineDimensions"

import { OptionsBar } from "../../components/individualProductPage/FeaturedProduct/FeaturedProductStyles/OptionsBar"
//End Main Images

import MiniHdOptions from "../../components/individualProductPage/MiniHdOptions"
import SEO from "../../components/SEO"
import Head from "../../components/Head"
import IndividualMachine from "../../components/individualProductPage/individualMachine"

const MediaTilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 840px) {
    max-width: 650px;
  }
`

const ControllerImageContainer = styled.div`
  max-width: 1400px;
  margin: 8rem auto;
  text-align: center;
  color: red;

  h1 {
    margin: 3rem 0;
    font-size: 3em;
  }

  img {
    width: 100%;
    height: auto;
  }
`

const SizeBtnContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`

const MicroHDProductPage = props => {
  return (
    <>
      <SEO title="Micro-HD" />
      <Head title="Micro-HD" />
      <Layout>
        <IndividualMachine
          cylindricalThumb1={CylindricalThumb1Image}
          cylindricalThumb2={CylindricalThumb2Image}
          cylindricalThumb3={CylindricalThumb3Image}
          cylindricalThumb4={CylindricalThumb4Image}
          cylindricalThumb5={CylindricalThumb5Image}
          cylindricalMain1={CylindricalMain1Image}
          cylindricalMain2={CylindricalMain2Image}
          cylindricalMain3={CylindricalMain3Image}
          cylindricalMain4={CylindricalMain4Image}
          cylindricalMain5={CylindricalMain5Image}
          diskThumb1={DiskThumb1}
          diskThumb2={DiskThumb2}
          diskThumb3={DiskThumb3}
          diskThumb4={DiskThumb4}
          diskThumb5={DiskThumb5}
          diskMain1={DiskMain1Image}
          diskMain2={DiskMain2Image}
          diskMain3={DiskMain3Image}
          diskMain4={DiskMain4Image}
          diskMain5={DiskMain5Image}
          orbitalThumb1={OrbitalThumb1}
          orbitalThumb2={OrbitalThumb2}
          orbitalThumb3={OrbitalThumb3}
          orbitalThumb4={OrbitalThumb4}
          orbitalThumb5={OrbitalThumb5}
          orbitalMain1={OrbitalThumb1}
          orbitalMain2={OrbitalThumb2}
          orbitalMain3={OrbitalThumb3}
          orbitalMain4={OrbitalThumb4}
          orbitalMain5={OrbitalThumb5}
          brochureLink="https://www.dropbox.com/s/isekh8ont113wxr/FC-V2-WALK-BEHIND-SM.pdf?dl=0"
          techLink="/FC-V2-WALK-BEHIND-specs.pdf"
          partsLink="https://www.dropbox.com/s/wkp5415dkwdwl05/FC-MICRO-HD-v2-P-EN.pdf?dl=0"
          opLink="https://www.dropbox.com/s/mnz7nzrpr9fa9bb/FC-walk-behind-v2-OP-EN.pdf?dl=0?dl=0"
          title="Micro-HD Walk Behind Floor Scrubber"
          textContent="Loaded with all the features of our larger walk behind scrubbers, the Micro HD is big on performance and small in size. The compact design creates an agile machine with the power to clean any floor. Rubberized floors, grouted tile, exposed aggregate and concrete floors can be cleaned with ease and left dry with powerful vacuum system and scrub motors on the Micro-HD. "
          mobileTitle="Micro-HD Walk Behind Floor Scrubber"
          pageUrl=""
          specifications="Cleaning Path: 17-26 | Run Time: Up To 2.5 Hours | Tank Capacity: Sol: 13 Gal, Rec: 15 Gal"
          videoSource="https://www.youtube.com/embed/NaGoDJxcSGM"
          upLink="/walk-behind-floor-scrubbers/mini-hd"
          machineName="Micro-HD"
          downLink="/walk-behind-floor-scrubbers/micromini"
          pmLink="/Walk-Behind-V2-PM.pdf"
          mtLink="https://www.dropbox.com/s/sx03160k2ppabfl/daily-weekly-maintenance-premium-v21306.pdf?dl=0"


        />
        <div className="tiles-container">
          <MediaTile
            title="Why FactoryCat ?"
            subTitle="Walk Behind Floor Scrubbers"
            link="/v2-you-be-the-judge.pdf"
          />
          <MediaTile
            title="Videos"
            subTitle="Walk Behind Floor Scrubbers"
            link="/videos"
          />
          <MediaTile
            link="/images#walkie-scrubbers"
            title="Images"
            subTitle="Walk Behind Floor Scrubbers"
          />
          <MediaTile
            title="Applications"
            subTitle="Walk Behind Floor Scrubbers"
            link="/applications"
          />
        </div>
        <MainSpecBar
          bgImage={StealBackground}
          machineImage={SpecBarMachineImage}
          myColor="white"
          machineName="Micro-HD"
          category="Walk Behind Floor Scrubber"
          path='17 - 26"'
          path2="(43.2 - 66 cm)"
          runTime="Up To 2.5 Hours"
          solution="Solution: 13 Gal"
          solutionLiter="(49.2L)"
          recovery="Recovery: 15 Gal"
          recoveryLiter="(58.8L)"
        />
        <ControllerImageContainer>
          <h1>Choose Your Controller</h1>
          <img src={ControllerImage} alt="" />
        </ControllerImageContainer>
        <MadeInUSA bgImage={UsaBackground} />
        <div className="spec-title-wrapper">
        <SectionTitle title="Machine Overview" />
        </div>
        <MachineOverview image={MachineOverviewImage} />
        <HeavyDuty
          bgImage={HeavyDutyBackground}
          innerText="HEAVY DUTY CLEANING"
        />
        <MachineDimensions
          image={MachineDimensionImage}
          sizeTitle="Size (L x W x H):"
          size='45" x 21" x 39'
          weightTitle="Weight (w/o batteries):"
          weight="278 - 328 lbs"
          voltageTitle="System Voltage:"
          voltage="24 VDC"
          stdBatteryTitle="Standard Battery:"
          battery="130 ah WET"
          powerTitle="Power:"
          power="0.75hp"
          tankTitle="Tank Material:"
          tank='Poly 5/16"'
        />
        <OptionsBar>Options</OptionsBar>
        <MiniHdOptions />

        <div id="demo-form"></div>
        <div className="spec-title-wrapper">
        <SectionTitle title="Request a Demo" />
        </div>
        <RequestDemoForm image={CylindricalMain1Image} />
      </Layout>
    </>
  )
}

export default MicroHDProductPage
